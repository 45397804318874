const origin = location.origin
export const headers = {
  fuwu: origin + '/fw',
  xunjian: origin + '/xj',
  peisong: origin + '/ps',
  voice: origin + '/aivoice'
}
export const rolesMap = {
  admin: '1', // 超管
  notAdmin: '0' // 普通管理员
}

export const breakWidth = 992

//平台类型
export const platformsType = {
  0: '服务',
  1: '巡检',
  2: '配送',
  3: '教学车',
  4: '清扫',
  5: '消杀'
}
// 0离线，1空闲，2忙碌，3异常 9-充电中 99-异常在线
export const robotStatusMap = {
  offline: 0,
  idle: 1,
  busy: 2,
  abnormal: 3,
  charge: 9,
  abnormalOnline: 99
}
export const amrStatusColorMap = {
  0: '#d7d7d7',
  1: '#409eff',
  2: '#f59a23',
  3: '#ff5a5a',
  9: '#4ecb73',
  99: '#cd0019'
}
export const robotStatusClass = {
  0: 'amr-status-0',
  1: 'amr-status-1',
  2: 'amr-status-2',
  3: 'amr-status-3',
  9: 'amr-status-9',
  99: 'amr-status-99'
}
export const taskTypeLabelMap = {
  0: 'PPT讲解',
  1: '语音触发',
  2: '定时',
  3: '循环',
  4: '带路',
  5: '巡检任务',
  6: '教学车任务',
  7: '派送任务',
  8: '揽件任务'
}

export const taskStatusLabelMap = {
  0: '失败',
  1: '已完成',
  2: '被中断',
  3: '执行中',
  4: '已终止'
}

export const amrStatusMap = {
  0: '离线',
  1: '空闲',
  2: '忙碌',
  3: '异常',
  9: '充电中',
  99: '异常在线'
}

export const stopMap = {
  0: '未触发',
  1: '触发',
  '-1': '未知'
}
//平台类型
export const amrTypeMap = {
  0: '室内服务',
  1: '室外巡检',
  2: '室外配送',
  3: '教学车',
  4: '室内清洁',
  5: '室内消杀'
}
